import React from "react";
import google from "../img/logos/google.png";
import yelp from "../img/logos/yelp.png";
import fb from "../img/logos/fb.png";
import angies from "../img/logos/angies.png";
import bbb from "../img/logos/bbb.png";
import houzz from "../img/logos/houzz.png";
import guildquality from "../img/logos/guildquality.png";

const ReviewTilesComponent = ({ data, twoThirds }) => {
  return (
    <section className="bg-gradient-to-b from-neutral-100 to-white relative shadow-sm py-4 font-display">
      <div className={`max-w-7xl flex flex-wrap py-3 mx-auto`}>
        <div className={`w-full flex items-center  `}>
          <div className="grid grid-cols-3 md:grid-cols-5 gap-3 md:gap-5 text-center w-full">
            <div className="text-center bg-white shadow-sm rounded-xl py-3">
              <img
                src={yelp}
                alt="Yelp Reviews"
                className="mb-0 mx-auto"
                style={{ maxWidth: "72px", marginTop: "-4px" }}
              />
              <div>
                <span className="block text-gray-700 font-medium text-sm mt-2">5/5 Rating</span>
              </div>
            </div>
            <div className="text-center bg-white shadow-sm rounded-xl py-3">
              <img
                src={google}
                alt="Google Reviews"
                className="mb-0 mx-auto"
                style={{ maxWidth: "75px" }}
              />{" "}
              <div>
                <span className="block text-gray-700 font-medium text-sm mt-3">4.9/5 Rating</span>
              </div>
            </div>
            <div className="text-center bg-white shadow-sm rounded-xl py-3">
              <img
                src={fb}
                alt="Facebook Reviews"
                className="mb-0 mx-auto"
                style={{ maxWidth: "85px" }}
              />
              <div>
                <span className="block text-gray-700 font-medium text-sm mt-2">5/5 Rating</span>
              </div>
            </div>
    <div className={`text-center bg-white shadow-sm rounded-xl py-3 ${twoThirds && 'md:hidden'}`}>
              <img
                src={angies}
                alt="Angies List Reviews"
                className="mb-0 mx-auto"
                style={{ maxWidth: "85px" }}
              />
              <div>
                <span className="block text-gray-700 font-medium text-sm mt-2">4/5 Rating</span>
              </div>
            </div>
            <div className={`text-center bg-white shadow-sm rounded-xl py-3 `}>
              <img
                src={bbb}
                alt="GuildQuality Reviews"
                className="mb-0 mx-auto"
                style={{ maxWidth: "75px" }}
              />
              <div>
                <span className="block text-gray-700 font-medium text-sm mt-2">A+ Rated Rating</span>
              </div>
            </div>

                      {/*
            <div className={`text-center bg-white shadow-sm rounded-xl py-3 ${twoThirds && 'md:hidden'}`}>
              <img
                src={houzz}
                alt="Houzz Reviews"
                className="mb-0 mx-auto"
                style={{ maxWidth: "85px" }}
              />
              <div>
                <span className="block text-gray-700 font-medium text-sm mt-2">5/5 Rating</span>
              </div>
            </div>

            <div className={`text-center bg-white shadow-sm rounded-xl py-3 `}>
              <img
                src={bbb}
                alt="GuildQuality Reviews"
                className="mb-0 mx-auto"
                style={{ maxWidth: "75px" }}
              />
              <div>
                <span className="block text-gray-700 font-medium text-sm mt-2">A+ Rated Rating</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewTilesComponent;
