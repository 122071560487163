import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from './atoms/Button'




const ItemTitle = styled.h3`
  margin-bottom: 0.25em;
`
const ItemExcerpt = styled.p`
  margin-bottom: 0.65em;
  color: #666;
  font-size: 0.9em;
`

const ImageContainer = styled.div``

const BodyContainer = styled.div`
  padding: 1.25em;
  padding-left: 1.75em !important;
`

const SingleItem = ({ itemList }) => {
  const menuItems = itemList.map((item, index) => (
    <div
      key={index}
      className={'flex overflow-hidden bg-white rounded-2xl  shadow-md flex-wrap'}
    >
      <div className="w-full md:w-1/4 shrink-0">
        <GatsbyImage
          image={getImage(item.heroImage)}
          alt={item.serviceTitle}
          className="w-full md:w-auto md:h-full"
        />
      </div>


      <div className='px-8 py-8 md:w-3/4'>
        <span className='font-bold font-display text-xl block'>{item.serviceTitle}</span>
        <ItemExcerpt>{item.excerpt}</ItemExcerpt>

        {item && item.slug ? (
          <Button
            to={`/${item.slug}`}
            title={'Learn more about ' + item.serviceTitle}
          >
            Learn More
          </Button>
        ) : null}
      </div>
    </div>
  ))
  return menuItems
}

const ItemListing = ({ itemList }) => {
  return (
    <div className='space-y-6'>

      <SingleItem itemList={itemList} />

    </div>
  )
}

export default ItemListing
