import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Button from '../components/button'

// Define the styled components here
const ItemContainer = styled.div`
  margin-bottom: 2em;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const ContentContainer = styled.div`
  padding: 30px; 
`

const PublishDate = styled.div`
  font-size: 0.85em;
  color: #666;
`

const Heading = styled.h2`
  font-size: 1.5em;
  margin-bottom: 0.5em;
  font-weight:700;
`

const Excerpt = styled.p`
  font-size: 0.95em;
  color: #333;
`

const OfferPostItem = ({
  title,
  slug,
  image,
  className,
  excerpt,
  notificationBarActionText,
  page = 'offers' // Default value for `page` prop
}) => {
  // Calculate the last date of the current month
  const getLastDateOfCurrentMonth = () => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay.toLocaleDateString('en-US');
  };

  return (
    <ItemContainer className={className}>
      <GatsbyImage
        image={getImage(image)}
        alt={title}
        style={{ width: '100%', height: '220px', objectFit: 'cover' }}
      />
      <ContentContainer>
        <Heading>{title}</Heading>
        {excerpt ? <Excerpt>{excerpt}</Excerpt> : null}
        <p className="text-sm pb-4 italic mb-0 mt-4">
          Offer Expires {getLastDateOfCurrentMonth()}
        </p>
        <Button
          to={`/${page}/` + slug + `/`}
          className='font-display  inline-flex outline-none active:scale-[.97] transition px-4 py-3 md:py-3 md:px-6 text-sm rounded-xl font-medium text-sm  transition duration-200 ease-in-out text-brand-500 bg-white border-2 border-white  hover:border-brand-100 hover:bg-brand-100 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300 items-center justify-center !border-1 !border-brand-500 '
          color={'secondary'}
          title={
            notificationBarActionText
              ? notificationBarActionText
              : 'Learn more about ' + title
          }
        >
          {notificationBarActionText ? notificationBarActionText : 'Learn More'} →
        </Button>
      </ContentContainer>
    </ItemContainer>
  )
}

export default OfferPostItem
