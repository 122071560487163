export function ReturnSourceFillColor(source) {
  switch (source) {
    case 'yelp':
      return '#f71919'
    case 'google':
      return '#fabc04'
    case 'bbb':
      return '#005e88'
    case 'guildquality':
      return '#002d77'
    case 'homeadvisor':
      return '#fa931c'
    case 'facebook':
      return '#0766ff'
    case 'houzz':
      return '#8bc542'
    case 'angieslist':
      return '#ef5b4e'
    default:
      return null
  }
}
