import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const FooterAreasServedList = () => (
  <StaticQuery
    query={graphql`
      query FooterAreasServedListQuery {
        allContentfulServiceArea(sort: { fields: location, order: ASC }) {
          edges {
            node {
              id
              valueTrackingId
              marketSegment
              slug
              location
            }
          }
        }
      }
    `}
    render={(data) => {
      // Return an empty div or any other content you'd like to keep
      return <div></div>
    }}
  />
)

export default FooterAreasServedList
