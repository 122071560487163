import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

const AngiesListAward = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "angieslist-award.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 370)
          }
        }
      }
    `}
    render={(data) => {
      return null
      return (
        <GatsbyImage
          className="mb-4 shadow-md border-radius"
          image={getImage(data.fileName.childImageSharp)}
          alt="Angie's List Super Service Award 2019"
        />
      )
    }}
  />
)
export default AngiesListAward
