import React from "react"; 

import chamberlain from "../images/logos/chamberlain.jpg"
import clopay from "../images/logos/clopay.svg"
import craftsman from "../images/logos/craftsman.png"
import genie from "../images/logos/genie.jpg"
import liftmaster from "../images/logos/listmaster.png"
import overhead from "../images/logos/overhead.jpg"
import raynor from "../images/logos/raynor.png"
import wdalton from "../images/logos/dooridentify-logo-waynedalton.png"
import chi from "../images/logos/CHI-ColorLogo.jpg"
import chd from "../images/logos/carriaged-house-door.svg"
const products = [
  {
    brand: 'Crafstman',
    logo: craftsman,
  },
  {
    brand: 'Chamberlain',
    logo: chamberlain,
  },

  {
    brand: 'Liftmaster',
    logo: liftmaster,
  },
  {
    brand: 'Clopay',
    logo: clopay,
  },
  {
    brand: 'Genie',
    logo: genie,
  },
  
  {
    brand: 'Amarr',
    logo: overhead,
  }, 
  {
    brand: 'Raynor',
    logo: raynor,
  },
  // {
  //   brand: 'Carriage House Door',
  //   logo: chd,
  // },
  {
    brand: 'Wayne Dalton',
    logo: wdalton,
  },
  {
    brand: 'CHI',
    logo: chi,
  }, 
];
 
const Products = () => {
 
  return (
    <section className="bg-gradient-to-b from-neutral-100 to-white relative shadow-sm py-12 md:py-16 font-display px-6 md:px-8 border-t-4 ">
        <h4 className="text-center text-brand-600 font-display text-2xl md:text-3xl mx-auto mb-4">1A Garage Doors only uses the best products for your home.
        
        </h4>
      <p className="font-normal text-center text-gray-800 font-display text-lg md:text-xl mx-auto mb-4 max-w-3xl">  We Service All Major Brands of Garage Door Products and Openers.
</p>
      <div className={`max-w-7xl flex flex-wrap py-3 mx-auto`}>
    

        <div className={`w-full flex items-center  `}>
        
          <div className="grid grid-cols-4 md:grid-cols-9 gap-3 md:gap-5 text-center w-full">
            {products.map((item, i) => (
              <div className="text-center bg-white flex items-center justify-center flex-col shadow-sm rounded-xl p-3"> 
                <div className="flex-1 h-full flex items-center">
                <img 
      src={item.logo} 
        class="w-full  flex"
      
      />
                </div><div className="sr-only">{item.brand}</div>
              </div>
            ))} 
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
