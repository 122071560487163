import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

const ElitePreferredStatus = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "elite-preferred.png" }) {
          childImageSharp {
            gatsbyImageData(width: 360)
          }
        }
      }
    `}
    render={(data) => {
      return (
        <div className="mb-3 bg-white border-radius shadow-md p-4 jhsidingbackground">
          <GatsbyImage
            className="mb-3 border-radius shadow-lg"
            image={getImage(data.fileName.childImageSharp)}
            alt="James Hardie Elite Preferred Contractor Alliance"
          />

          <h4 className="font-bold leading-6 px-1 text-black text-center text-xl mb-2 mt-4">
            Proud to be James Hardie's Elite Preferred Contractor
          </h4>
          <p className="mt-3 mb-1 leading-medium px-3 text-center text-15 text-black">
            Elite Preferred Contractor status is a measure of efficiency and
            standardized business processes, and is the highest level awarded to
            any business in the five-level model developed by James Hardie.
          </p>
        </div>
      )
    }}
  />
)
export default ElitePreferredStatus
