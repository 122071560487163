import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";

const SidingCoupon = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "siding-offer-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 370)
          }
        }
      }
    `}
    render={(data) => {
      return null;
      //temporary return null for 1A Garage Doors while we get team pic
      return (
        <GatsbyImage
          className="shadow-md border-radius mb-4"
          image={getImage(data.fileName.childImageSharp)}
          alt="Siding Special Offer"
        />
      );
    }}
  />
);
export default SidingCoupon;
