import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { ChevronLeft, ChevronRight } from 'react-feather'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ArrowContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 40%;
  height: 27px;
  width: 27px;
  background: #f6f6f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    left: -27px;
    svg {
      margin-left: -2px;
    }
  }
  &.right {
    right: -27px;
    svg {
      margin-left: 2px;
    }
  }
  svg {
    stroke-width: 3;
  }
`
const AwardContainer = styled.div`
  padding: 0 10px;
  img,
  .gatsby-image-wrapper {
    box-shadow: 0 6px 12px -2px rgba(47, 47, 47, 0.25);
    border-radius: 2px;
  }
`

const AwardTitle = styled.h4`
  font-size: 0.75em;
  margin-bottom: 0.5em;
  line-height: 1.3;
  padding-top: 15px;
  text-align: center;
  color: #2d2d2d;
  font-weight: normal;
`
const OuterPadding = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`

const NextArrow = (props) => {
  const { onClick } = props

  return (
    <div onClick={onClick}>
      <ArrowContainer className="left">
        <ChevronLeft size={20} />
      </ArrowContainer>
    </div>
  )
}

const PrevArrow = (props) => {
  const { onClick } = props

  //auto scroll
  //setInterval(() => onClick(), 1500)

  return (
    <div onClick={onClick}>
      <ArrowContainer className="right">
        <ChevronRight size={20} />
      </ArrowContainer>
    </div>
  )
}

const AwardComponenet = (props) => {
  let { title, image } = props
  return (
    <AwardContainer>
      <GatsbyImage
        image={getImage(image)}
        // style={{ minHeight: '100%', margin: '0 auto' }}
        alt={title}
      />
      <AwardTitle>{title}</AwardTitle>
    </AwardContainer>
  )
}

const AwardsCertsSlider = (props) => (
  <StaticQuery
    query={graphql`
      query SiteAwardsCerts {
        allContentfulAwardsCertifications {
          edges {
            node {
              id
              title
              awardImage {
                gatsbyImageData(width: 500, placeholder: NONE, quality: 80)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1300,
        speed: 300,
        slidesToShow: props.slidesToShow ? props.slidesToShow : 1,
        slidesToScroll: 1,
        nextArrow: <PrevArrow />,
        prevArrow: <NextArrow />,
        responsive: [
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      }
      return (
        <OuterPadding>
          <Slider {...settings}>
            {data.allContentfulAwardsCertifications.edges.map((item, i) => (
              <AwardComponenet
                key={i}
                title={item.node.title}
                image={item.node.awardImage}
              />
            ))}
          </Slider>
        </OuterPadding>
      )
    }}
  />
)

export default AwardsCertsSlider
