import React, { useState, useEffect, Fragment } from 'react'; 
import { StaticQuery, graphql, Link } from 'gatsby';
import MenuItemDropdown from './MenuItemDropdown';
import ContactFormModal from './ContactFormModal';
import Logo from './logo-svg';
import Button from './atoms/Button'; 
import { Popover } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { NavLinks } from './NavLinks';

import { 
  ArrowRightCircle,
  BadgeCheck,
  ChevronRight,
  ChevronUpIcon,
  ListOrdered,
  MenuIcon,
  Phone,
  Users2,
} from 'lucide-react';

export const featureList = [
  {
    item: (
      <>
        Family Owned
        <span className="hidden md:inline"> & Operated</span>
      </>
    ),
    icon: Users2,
  },
  {
    item: <>Licensed & Insured</>,
    icon: BadgeCheck,
  },
];

function MobileNavLink({ children, ...props }) {
  return (
    <Popover.Button as={Link} className="block text-base leading-7 font-normal text-gray-700 py-1" {...props}>
      {children}
    </Popover.Button>
  );
}

function Navbar({ headerTransparent = false, shadow = true, pageProps }) {
  const companyname = '1A Garage Doors'; 
  const phonenumber = '(916) 838-8182';
  const mainnavigationstyle = 'inline';

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contactFormOpen, setContactFormOpen] = useState(false);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add('menu-expanded');
    }

    return function cleanup() {
      document.body.classList.remove('menu-expanded');
    };
  }, [mobileMenuOpen]);

  const MenuItem = ({ item, source, headerTransparent }) => {
    let itemId = item.id;
    let title = item.name;
    let slug = item.url;

    if (item.submenu && item.submenu.length > 0) {
      return (
        <MenuItemDropdown item={item} label={title} type={1} source={source} headerTransparent={headerTransparent} />
      );
    }
    return (
      <Link
        to={`${slug && slug.length < 2 ? '/' : slug}`}
        activeClassName=" !border-brand-600 text-neutral-700"
        className={`${
          source && source == 'mobilemenu'
            ? 'block py-4 px-8 border-b border-neutral-300'
            : `p-3 py-7 border-transparent     ${
                headerTransparent
                  ? 'text-neutral-600 hover:text-neutral-900 '
                  : 'text-neutral-700 hover:text-neutral-900    '
              }`
        }  font-semibold text-[15px] font-display text-base  transition-colors duration-150 ease-in border-b-[3px] border-transparent
        

        relative after:bg-brand-600 after:absolute after:h-[3px] after:w-0 after:bottom-[-3px] after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer
        
        
        `}
      >
        {item.name}
      </Link>
    );
  };

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQueryInNav {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => {
        const items = data.allContentfulNavigation.edges[0].node.menuItems;
        return (
          <>
            <header>
              <nav className="bg-neutral-900 -600  relative z-50">
                <div className="max-w-7xl mx-auto px-4 md:px-6 flex justify-between  items-center">
                  <div className="relative z-10 flex items-center gap-16 py-3 md:py-3 logo-navbar-dark">
                    <Link to="/" className="block   w-36 md:w-48 shrink-0" title={companyname}>
                      <Logo white={true} classes={'transition text-white'} />
                    </Link>
                  </div>

                  <div className="    flex-1 lg:flex justify-center md:justify-between ">
                    <div className="hidden lg:block font-display mx-auto py-2 text-sm md:text-base text-center  px-4 md:px-6">
                      <span className="block text-white  font-normal">#1 Rated Garage Door Company</span>
                      <span className="text-gray-200 font-normal">serving Sacramento, CA</span>
                    </div>
                    <div className="flex items-center font-display justify-end">
                      <div className="hidden md:block sm:pr-8 text-center text-neutral-300 font-normal ">
                        Call Us Today!
                        <a
                          className={`mb-1 block font-medium text-base sm:text-xl lg:text-xl  tracking-tight ${
                            headerTransparent ? 'text-white' : 'text-white'
                          }`}
                          href={`tel:${phonenumber}`}
                        >
                          {phonenumber}
                        </a>
                      </div>
                      <div>
                        <div className="hidden md:block mr-4 z-[1000] relative">
                          <Button onClick={() => setContactFormOpen(true)} >
                            <span className="text-[13px] sm:text-sm font-medium p-1"><span className='hidden md:inline'>Get a </span>Quick Estimate</span>{' '}
                            <ArrowRightCircle className="w-4 h-4 md:h-5 md:w-5 ml-1" />
                          </Button>
                          <ContactFormModal modalOpen={contactFormOpen} onModalClose={setContactFormOpen} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-6 font-display lg:hidden">
                    <Popover className="lg:hidden flex">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className="text-white relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
                            aria-label="Toggle site navigation"
                          >
                            {({ open }) =>
                              open ? <ChevronUpIcon className="h-6 w-6 text-black" /> : <MenuIcon className="h-6 w-6" />
                            }
                          </Popover.Button>
                          <AnimatePresence initial={false}>
                            {open && (
                              <>
                                <Popover.Overlay
                                  static
                                  as={motion.div}
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                                />
                                <Popover.Panel
                                  static
                                  as={motion.div}
                                  initial={{ opacity: 0, y: -32 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  exit={{
                                    opacity: 0,
                                    y: -32,
                                    transition: { duration: 0.2 },
                                  }}
                                  className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                                >
                                  <div className="space-y-3">
                                    {items.map((item) => (
                                      <>
                                      <MobileNavLink to={item.url}>{item.name}</MobileNavLink>
                                      {item.submenu && (
                                        <div className="pl-6 py-1">
                                          {item.submenu[0].menuItems.map((item, i) => (
                                            <MobileNavLink to={item.url}>{item.name}</MobileNavLink>
                                          ))}
                                        </div> 
                                      )}
                                      </>
                                    ))}
                                    <div className="border-t  mt-4 pt-4 space-y-3">
                                      {/* {secondaryNavigation.map((item) => (
                                <MobileNavLink to={item.href}>{item.label}</MobileNavLink>
                              ))} */}
                                    </div>
                                  </div>

                                  <div className="mt-8 flex flex-col gap-4">
                                  <Button onClick={() => setContactFormOpen(true)} >
                            <span className="text-[13px] sm:text-sm font-medium p-1">Get Pricing</span>{' '}
                            <ArrowRightCircle className="h-3 w-3 sm:w-4 sm:h-4 ml-1" />
                          </Button>
                                    <Button target="_blank" href={`tel:${phonenumber}`} variant="outline" className="shrink-0 flex items-center">
                                      <Phone className="w-4 h-4 mr-1" />
                                      {phonenumber}
                                    </Button>
                                  </div>
                                </Popover.Panel>
                              </>
                            )}
                          </AnimatePresence>
                        </>
                      )}
                    </Popover>
                  </div>
                </div>
                <div className="bg-white relative shadow-soft">
                  <div className="max-w-7xl mx-auto  px-4 md:px-6 hidden lg:flex lg:gap-10">
                    <NavLinks navigation={items} pageProps={pageProps} />{' '}
                  </div>{' '}
                </div>
              </nav>
              
            </header>
            <div className='md:hidden bg-white/80 backdrop-blur-xl shadow py-2 px-4 sticky top-0 z-[40] flex items-center justify-between'>
              <div className='font-display text-lg'>
                <span className='block font-normal text-sm text-gray-600'>#1 Garage Door Company</span>
              <a href={`tel:${phonenumber}`} className='block font-bold text-lg tracking-tight'>{phonenumber}</a>
              </div>
            <Button onClick={() => setContactFormOpen(true)} className=' !p-2 !px-4'>
                            <span className="text-[13px] sm:text-sm font-medium p-1">Get Pricing</span>{' '}
                            <ArrowRightCircle className="h-3 w-3 sm:w-4 sm:h-4 ml-1" />
                          </Button>
              </div>
          </>
        );
      }}
    />
  );
}

export default Navbar;
