import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "../components/atoms/Button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Popup from "reactjs-popup";
import ContactForm from "../components/ContactForm";
import { X } from "react-feather";
import EstimateForm from "../components/estimate-form";
import { ArrowRight } from "lucide-react";

const CTAOuterContainer = styled.section`
  padding: 4em 2em;
  height: auto;
  background-size: cover;
  position: relative;
  @media (max-width: 992px) {
    text-align: center;
  }
  .gatsby-image-wrapper {
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: #000;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0.5;
    }
  }
  .popup-overlay {
    overflow: scroll !important;
  }
  .popup-content {
    background: #f5f5f5 !important;
    max-width: 767px !important;
    text-align: left !important;
  }
`;

const ContentContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0.25em;
  color: #fff;
  font-size: calc(0.75vw + 1.15em);
`;
const Subheading = styled.p`
  color: #f2f2f2;
  margin: 0;
  font-size: calc(0.07vw + 1em);
`;
const CTA = ({ title, customTitle, customDesc, bgimage, serviceHeroImage, btn, props }) => {
  const CTABGImage = () => (
    <GatsbyImage
      className="object-cover object-center rounded-3xl shadow-2xl"
      image={getImage(bgimage ? bgimage : serviceHeroImage)}
      style={{
        left: "0",
        width: "100%",
        height: "100%",
        top: "0",
        zIndex: "-1",
        position: "absolute",
      }}
      alt=""
    />
  );

  return (
    <div className="relative py-16 bg-white">
      <div className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block" aria-hidden="true" />
      <div className="max-w-7xl mx-auto bg-brand-600 lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true" />
            <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 z-20 sm:aspect-h-1 lg:aspect-w-1">
                {bgimage || serviceHeroImage ? <CTABGImage /> : null}
              </div>
            </div>
          </div>

          <div className="relative bg-gradient-to-b from-brand-600 to-brand-800 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
              <svg
                className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-brand-500" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
              <svg
                className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-brand-500" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
            </div>
            <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <h2 className="text-3xl font-bold text-white" id="join-heading">
                {customTitle ? customTitle : title}
              </h2>
              <p className="text-lg text-white">{customDesc && customDesc}</p>
              {/* <Popup
                trigger={
                  <button
                    title={'Get Started with ' + title}
                    style={{ borderWidth: '0' }}
                    className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-2xl shadow-md text-base font-medium text-brand-700 hover:bg-gray-50 sm:inline-block sm:w-auto"
                  >
                    Get Started!
                  </button>
                }
                modal
                closeOnDocumentClick
                className="p-2 block bg-white rounded-2xl"
                style={{ borderRadius: '10px' }}
              >
                {(close) => (
                  <div className="p-2 block bg-white">
                    <button
                      style={{ borderWidth: '0', float: 'right' }}
                      className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                      onClick={() => {
                        close()
                      }}
                    >
                      <X color={'#333'} strokeWidth={'3'} className="d-block" />
                    </button>
                    <div className="p-3 py-4 block">
                      <h2
                        className="mb-1 popupheading"
                        style={{ lineHeight: '1.05' }}
                      >
                        Start With a Consultation!
                      </h2>
                      <p
                        style={{
                          color: '#666',
                          lineHeight: '1.5',
                          fontSize: '.9em',
                        }}
                        className="mb-3 popupdesc"
                      >
                        Contact us today to learn more about our extensive home remodeling services in Sacramento and relax, knowing that the work is in the hands of capable and experienced professionals.
                      </p>
                      <ContactForm />
                    </div>
                  </div>
                )}
              </Popup> */}
              <Button
                  to="/contact/"
                title={"Get Started with " + title}
                >
                Get Started <ArrowRight size={16} className="ml-2"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CTA.propTypes = {
  title: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
};

export default CTA;
