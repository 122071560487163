import { useEffect } from 'react'
import { getCity } from '../helpers/adRefTracking'

export const useHiddenmsClassCity = ({
  cityToMatch = 'bayarea',
  handler,
} = {}) => {
  const refs = []
  useEffect(() => {
    const city = getCity()
    if (refs.length > 0 && city == cityToMatch) {
      refs.map(ref => {
        if (ref.current) {
          if (!handler) {
            const className = ref.current.parentNode.classList
            ref.current.parentNode.className = className + ' hiddenms'
          } else {
            handler(ref)
          }
        }
        return ref
      })
    }
  })
  return refs
}
