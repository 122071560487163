import React, { useState, useEffect, useRef } from 'react'
import Popup from 'reactjs-popup'
import Cookies from 'js-cookie'

const usePromotion = () => {
  const [isOpen, setState] = useState(false)
  const wasClosed = Cookies.get('promotion')

  // const mounted = useRef()

  // useEffect(() => {
  //   if (wasClosed === undefined) {
  //     if (!mounted.current) {
  //       mounted.current = true
  //       const timeoutId = setTimeout(() => {
  //         setState(true)
  //         clearTimeout(timeoutId)
  //       }, 20000)
  //     }
  //   }
  // })

  const closePromotion = async _ => {
    setState(false)
    Cookies.set('promotion', false, { expires: 1 })
  }

  return [isOpen, closePromotion]
}

const Promotion = () => {
  const [isOpen, closePromotion] = usePromotion()
  return (
    <Popup open={isOpen} onClose={closePromotion} lockScroll modal>
      {close => (
        <div className="modal">
          <a className="close" onClick={close}>
            &times;
          </a>
          <div className="header"> Get 5% OFF Your Renovation </div>
          <div className="content">
            {' '}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a
            nostrum. Dolorem, repellat quidem ut, minima sint vel eveniet
            quibusdam voluptates delectus doloremque, explicabo tempore dicta
            adipisci fugit amet dignissimos?
            <br />
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Consequatur sit commodi beatae optio voluptatum sed eius cumque,
            delectus saepe repudiandae explicabo nemo nam libero ad, doloribus,
            voluptas rem alias. Vitae?
          </div>
          <div className="actions">
            <Popup
              trigger={<button className="button"> Trigger </button>}
              position="top center"
              closeOnDocumentClick
            >
              <span>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae
                magni omnis delectus nemo, maxime molestiae dolorem numquam
                mollitia, voluptate ea, accusamus excepturi deleniti ratione`
                sapiente! Laudantium, aperiam doloribus. Odit, aut.
              </span>
            </Popup>
            <button
              className="button"
              onClick={() => {
                close()
              }}
            >
              close modal
            </button>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default Promotion
