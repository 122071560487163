import React from "react";
import styled from "styled-components";
import Reputation from "./reputation";
import WhyUs from "./whyus";
import ContactInfo from "./contact-info";
import AwardsCertsSlider from "./awards-certs";
import CardGeneral from "./card-general";

import { StaticQuery, graphql } from "gatsby";
import HappyCustomers from "./sidebars/happy-customers";
import SaganTeamPic from "./sidebars/sagan-team-pic";
import SidingCoupon from "./sidebars/siding-coupon";
import ElitePreferredStatus from "./sidebars/elite-preferred-status";
import { Link } from "gatsby";
import AngiesListAward from "./sidebars/angieslist";
import BestofHouzz from "./sidebars/bestofhouzz";
import TanklessWaterHeaterPromotion from '../components/coupons/TanklessWaterHeaterPromotion'
import FiftyOffWaterHeaterRepair from '../components/coupons/FiftyOffWaterHeaterRepair'


const MainContainerOuter = styled.div`
  width: 100%;
  background: white;
  margin-bottom: 1em;
`;

const SidebarTitle = styled.div`
  background: #f6f6f6;
  padding: 1em;
  h3 {
    margin: 0;
  }
`;
const SidebarContent = styled.div`
  padding: 1em;
`;

const Card = ({ sidebar, children }) => {
  if (sidebar.content.content.includes("{{AwardsCerts}}")) {
    //temp
    return null;
    return (
      <CardGeneral title={"Certifications"} className="remove-padding-shadow card-certs-green">
        <p className="text-gray-600 text-base">Check out our certifications</p>
        <AwardsCertsSlider />
      </CardGeneral>
    );
  }
  if (sidebar.content.content.includes("{{ReputationBar}}")) {
    return null;
    return (
      <CardGeneral title={"Find Us On"}>
        <Reputation />
        <Link
          className="button-style-primary  text-base font-semibold mt-4 rounded-full flex flex-col items-center justify-between col-span-1 md:col-span-2 lg:col-span-1 py-2 px-3 shadow-md hover:shadow-lg hover:bg-white hover:cursor-pointer cursor-pointer"
          to={"/reviews/all/"}
          state={{
            modal: true,
          }}
        >
          Read All Reviews
        </Link>
      </CardGeneral>
    );
  }
  if (sidebar.content.content.includes("{{Testimonials}}")) {
    return null;
    let limitTestimonialCategoryDisplayTo = null;
    if (sidebar.testimonialCategory) {
      limitTestimonialCategoryDisplayTo = sidebar.testimonialCategory.id;
    }
    return null;
  }
  if (sidebar.content.content.includes("{{WhyUs}}")) {
    return <><WhyUs /></>;
  }
  if (sidebar.content.content.includes("{{ContactInfo}}")) {
    return <ContactInfo />;
  }
  if (sidebar.content.content.includes("{{SidingSpecialOffer}}")) {
    return <SidingCoupon />;
  }
  if (sidebar.content.content.includes("150OFFTANKLESSREPLACEMENTUPGRADE")) {
    return <TanklessWaterHeaterPromotion/>
  }
  if (sidebar.content.content.includes("50OFFWATERHEATERREPLACEMENT")) {
    return <FiftyOffWaterHeaterRepair/>
  } 
  if (sidebar.content.content.includes("{{BestofHouzz}}")) {
    return <BestofHouzz />;
  }
  if (sidebar.content.content.includes("{{AngiesListAward}}")) {
    return <AngiesListAward />;
  }
  if (sidebar.content.content.includes("{{SaganTeamPic}}")) {
    return null;
  }
  if (sidebar.content.content.includes("{{HappyCustomers}}")) {
    return <HappyCustomers />;
  }
  if (sidebar.content.content.includes("{{ElitePreferredStatus}}")) {
    return <ElitePreferredStatus />;
  }
  if (sidebar.content.content.includes("blog"))
    return (
      <MainContainerOuter
        className={
          "border-radius shadow-md sidebar-card " + (sidebar && sidebar.cssClasses ? sidebar && sidebar.cssClasses : "")
        }
      >
        <SidebarTitle className="moduleheading">
          <h3>{sidebar.title}</h3>
        </SidebarTitle>
        <SidebarContent className="sidebarstyle">{children}</SidebarContent>
      </MainContainerOuter>
    );
  return (
    <MainContainerOuter
      className={
        "border-radius shadow-md sidebar-card " + (sidebar && sidebar.cssClasses ? sidebar && sidebar.cssClasses : "")
      }
    >
      <SidebarTitle className="moduleheading">
        <h3>{sidebar.title}</h3>
      </SidebarTitle>
      <SidebarContent className="sidebarstyle" dangerouslySetInnerHTML={{ __html: sidebar.content.content }} />
      
    </MainContainerOuter>
  );
};

export default Card;
