import { useEffect } from 'react'

// Match Function Needs to return a boolean expression whether the condition to add the class has been meet or to run the handler
export const useMatchHiddenClass = ({ matchFunction, handler } = {}) => {
  const refs = []
  useEffect(() => {
    if (refs.length > 0 && matchFunction()) {
      refs.map(ref => {
        if (ref.current) {
          if (!handler) {
            const className = ref.current.classList
            ref.current.className = className + ' hiddenms'
          } else {
            handler(ref)
          }
        }
        return ref
      })
    }
  }, [refs.length])
  return refs
}
