import React, { useState, useEffect, useCallback } from "react"; 

function FiftyOffWaterHeaterRepair({ frontmatter }) {
  return null
  return (
    <div className={`rounded-lg mb-2 shadow bg-white`} style={{"background": "linear-gradient(45deg, #2196F3 50%, white 50%)"}}>
      <div className="px-6 pt-6">
        <h2 className="text-gray-800 font-bold text-2xl md:text-3xl">
          50 f=odd
        </h2>
      </div>

      <div className="p-6">
         body
      </div>
    </div>
  );

}

export default FiftyOffWaterHeaterRepair;
