import React from "react";
import styled from "styled-components";

import { Link } from "gatsby";
import { CheckCircle } from "react-feather";

const AreasServedContainer = styled.div`
  h1 {
    font-size: calc(0.75vw + 1.5em);
  }
`;

function parseContent(string, altCity) {
  if (string) {
    return string.replace(/San Jose/g, altCity);
  }
}

export const AreasServed = (props) => {
  const { citiesList, className, serviceName = "", parentPageSlug, blogComponent } = props;

  const getServiceTitle = () => {
    if (serviceName.includes("Residential Remodeling Contractor")) return "Residential Water Heaters";
    if (serviceName.includes("Remodeling Outlet & Switches Installation"))
      return "Remodeling Outlet & Switches Services";
    if (serviceName.includes("Remodeling Panel")) return "Remodeling Panel Services";
  };

  const getServiceSlug = () => {
    if (serviceName.includes("Full Home Remodeling")) return "whole-house-remodeling";
    if (serviceName.includes("Kitchen Remodeling")) return "kitchen-remodeling";
    if (serviceName.includes("Bathroom Remodeling")) return "bathroom-remodeling";
  };

  const serviceTitle = getServiceTitle();
  const serviceSlug = getServiceSlug();

  return (
    <AreasServedContainer className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <div className="container is-service-page">
        <div className="pt-8 pb-5">
          {!blogComponent ? (
            <div className="pt-10 pb-5 bg-white rounded-2xl">
              <span className="px-8 block text-2xl font-bold text-gray-900 font-display">Other Service Areas</span>
              <ul className="flex flex-wrap list-none p-8">
                {citiesList.map((item, i) => {
                  return (
                    <li key={i} className="w-1/2 md:w-1/3 flex space-y-4 md:space-y-6">
                      <div className="flex-shrink-0 mr-2">
                        <CheckCircle fontWeight={900} strokeWidth={3} size={20} className="text-brand-500 mt-2" />
                      </div>{" "}
                      <Link
                        style={{ marginTop: 0, textDecoration: "none" }}
                        className="text-gray-800 underline-none"
                        title={`${parseContent(serviceName, item.node.location)}`}
                        to={`/${serviceSlug}-${item.node.slug}/`}
                      >
                        {item.node.location}
                        <span className="block text-sm font-normal text-gray-500">
                          {parseContent(serviceName, item.node.location)}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <ul className="flex flex-wrap list-none m-0">
              {citiesList.map((item, i) => (
                <li key={i} className="w-1/2 md:w-1/4 flex">
                  <div className="flex-shrink-0 pr-1">
                    <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </div>{" "}
                  <Link
                    className="text-gray-800 hover:text-brand-600"
                    title={`${item.location + " " + (serviceTitle ? serviceTitle : "")}`}
                    to={`/${serviceSlug ? serviceSlug : ""}-${item.slug + "/"}`}
                  >
                    {item.location}
                  </Link>
                </li>
              ))}
              sfsf
              {citiesList.map((item, i) => (
                <li key={i} className="w-1/2 md:w-1/4 flex">
                  <div className="flex-shrink-0 pr-1">
                    <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </div>{" "}
                  <Link
                    className="text-gray-800 hover:text-gray-800"
                    title={`${"Water Heaters " + item.location + ", CA"}`}
                    to={`/cities/${item.slug}/`}
                  >
                    {item.location}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </AreasServedContainer>
  );
};
