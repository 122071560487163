function b64e(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
}

function b64d(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return "%" + c.charCodeAt(0).toString(16);
      })
      .join("")
  );
}

function setObjectHash(key, myObject) {
  if (typeof window !== "undefined" && window.localStorage) {
    var newObject = {};
    Object.keys(myObject).map(function (value, index) {
      newObject[value] = b64e(myObject[value]);
    });
    window.localStorage.setItem(key, b64e(JSON.stringify(newObject)));
  }
}

function getObjectHash(key) {
  if (typeof window !== "undefined" && window.localStorage) {
    var myObject = window.localStorage.getItem(key);
    return (
      b64d(myObject) &&
      JSON.parse(b64d(myObject), function (key, value) {
        return key ? b64d(this[key]) : this[key];
      })
    );
  }
}

export function setItemToLStore(key, data) {
  setObjectHash(key, data);
}

export function getItemFromStore(key) {
  return getObjectHash(key);
}

// // Usage
// var userObject = { userId: 24, name: 'Jac|`` /\Baèéàòùu"aer' };
// document.body.appendChild(document.createTextNode('user: ' + JSON.stringify(userObject)));
// document.body.appendChild(document.createElement('br'));

// document.body.appendChild(document.createTextNode('localStorage.getItem(\'user\'): ' + localStorage.getItem('user')));
// document.body.appendChild(document.createElement('br'));
// document.body.appendChild(document.createTextNode('localStorage.getObject(\'user\').name: ' + localStorage.getObjectHash('user').name));
